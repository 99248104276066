const SearchPopularSearches = `
query SearchPopularSearches($search: String) {
  xsearchPopularSearches(search: $search) {
    code
    items {
      name
      num_results
      url
    }
    total_count
  }
}
`;

const getSearchPopularProducts = `
query SearchProducts($search: String) {
  xsearchProducts(search: $search) {
    code
    items {
      id
      sku
      name
      url_key
      url_suffix
      image {
        disabled
        label
        position
        url
      }
      small_image {
        disabled
        label
        position
        url
      }
      price_range {
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
    }
    total_count
  }
}
`;

const getBestsellerProducts = `
query SearchBestsellerProducts {
  xsearchBestsellerProducts {
    code
    items {
      id
      sku
      name
      url_key
      url_suffix
      image {
        disabled
        label
        position
        url
      }
      small_image {
        disabled
        label
        position
        url
      }
      price_range {
        minimum_price {
          discount {
            amount_off
            percent_off
          }
          final_price {
            currency
            value
          }
          fixed_product_taxes {
            label
          }
          regular_price {
            currency
            value
          }
        }
      }
    }
    total_count
  }
}
`;

export { SearchPopularSearches, getSearchPopularProducts, getBestsellerProducts };
