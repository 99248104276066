/* eslint-disable prefer-rest-params */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

export interface ISearchItem {
  searchValue: string;
  date: string | Date;
}

export function setRecentSearchLocalCache(key: string, value: ISearchItem) {
  if (!key || !value) return;

  const maxSearches = 8; // Maximum number of recent searches allowed

  const recentSearchesStr: string = window.localStorage.getItem(key);
  let recentSearches : Array<ISearchItem> = recentSearchesStr !== null ? JSON.parse(recentSearchesStr) : [];

  recentSearches = recentSearches.filter((item: ISearchItem) => item.searchValue !== value.searchValue);

  recentSearches.push({ ...value, date: new Date() });
  recentSearches = recentSearches.slice(-maxSearches);

  window.localStorage.setItem(key, JSON.stringify(recentSearches));
}

export function getFromLocalCache(key: string) {
  if (!key) return;
  if (typeof window !== 'undefined') {
    try {
      return JSON.parse(window.localStorage.getItem(key) || '');
    } catch {
      return window.localStorage.getItem(key);
    }
  }
}

export function removeLocalCacheItem(key: string) {
  if (!key) return;

  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
}
