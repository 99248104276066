







































































































































































































import {
  SfIcon,
  SfLoader,
  SfImage,
} from '@storefront-ui/vue';
import {
  defineComponent, onBeforeMount, ref, useContext, useRouter, watch,
} from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import {
  useApi, useImage, useProduct, Variables,
} from '~/composables';
import { setRecentSearchLocalCache, getFromLocalCache, removeLocalCacheItem } from '~/helpers/utils';
import { SearchPopularSearches, getSearchPopularProducts, getBestsellerProducts } from './SearchQuery.gql';

export interface PopularSearchesType {
  code: string,
  items: any[],
  total_count: number,
  __typename: string,
}
export interface SearchPopularSearchesResult {
  xsearchPopularSearches: PopularSearchesType
}
export default defineComponent({
  name: 'SearchResults',
  components: {
    SfIcon,
    SfLoader,
    SfImage,
    ProductListTemplate: () => import('~/components/ProductListTemplate.vue'),
  },
  setup(props, { emit }) {
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const { getMagentoImage, imageSizes } = useImage();
    const { getProductPath } = useProduct();
    const { query } = useApi();
    const router = useRouter();
    const searchValue = ref('');
    const timerID = ref<any>(null);
    const suggestionResult = ref<any[]>([]);
    const popularSearchResult = ref<any[]>([]); // left-side popular search menu
    const recentSearchList = ref<any[]>(getFromLocalCache('recent-searches') as any[]);
    const popularProducts = ref<any[]>([]);
    const isLoading = ref<boolean>(false);
    const getPopularSerach = async (value:string) : Promise<any[]> => {
      // eslint-disable-next-line no-param-reassign
      const { data } = await query<SearchPopularSearchesResult>(SearchPopularSearches, { search: value });
      const finalData = data && data.xsearchPopularSearches && data.xsearchPopularSearches.items ? data.xsearchPopularSearches.items : [];
      return finalData;
    };

    const fetchSearchProducts = async (value:string) => {
      isLoading.value = true;
      const param : Variables = {};
      if (value) {
        param.search = value;
      }
      const { data } = await query<any>(getSearchPopularProducts, param);
      const finalData = data && data.xsearchProducts && data.xsearchProducts.items ? data.xsearchProducts.items : [];
      isLoading.value = false;
      return finalData;
    };

    const getBestPopularProducts = async () => {
      isLoading.value = true;
      const { data } = await query<any>(getBestsellerProducts);
      const finalData = data && data.xsearchBestsellerProducts && data.xsearchBestsellerProducts.items ? data.xsearchBestsellerProducts.items : [];
      isLoading.value = false;
      return finalData;
    };

    const handleSearch = () => {
      // press enter or click search button
      if (searchValue) {
        setRecentSearchLocalCache('recent-searches', {
          searchValue: searchValue.value,
          date: '',
        });
        // setOpen(false);
        router.push(app.localePath(`/search?keyword=${searchValue.value}`));
        emit('close');
      }
    };
    const handleKeyDown = () => {
      handleSearch();
    };

    const handleRouter = (value: any, type: string) => {
      // setOpen(false);
      if (type === 'suggestion' && value && 'url' in value) {
        const key = value.url.split('/')[2]?.replace('?q=', '');
        router.push(app.localePath(`/search?keyword=${key}`));
      } else {
        router.push(app.localePath(`/search?keyword=${value}`));
      }
      emit('close');
    };

    const handleClearSearch = () => {
      removeLocalCacheItem('recent-searches');
      recentSearchList.value = [];
    };

    watch(searchValue, (newVal, oldVal) => {
      if (newVal.length >= 3) {
        const tmpSearchValue = newVal;
        if (timerID.value) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          clearTimeout(timerID.value);
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        timerID.value = setTimeout(async () => {
          const tmpData = await getPopularSerach(tmpSearchValue);
          suggestionResult.value = tmpData;
          popularProducts.value = [];
          const productList = await fetchSearchProducts(tmpSearchValue);
          popularProducts.value = productList.map((product, key) => {
            const item = product;
            if (!item.thumbnail && item.small_image) {
              item.thumbnail = product.small_image;
            }
            if (!item.url_rewrites && item.url_key && item.url_suffix) {
              item.url_rewrites = [];
              item.url_rewrites.push({
                url: `${item.url_key}${item.url_suffix}`,
              });
            }
            return item;
          });
        }, 500);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        clearTimeout(timerID.value);
      }
    });

    onBeforeMount(async () => {
      if (popularSearchResult.value.length === 0) {
        const tmp = await getPopularSerach('');
        popularSearchResult.value = tmp;
      }
      if (popularProducts.value.length === 0) {
        const productList = await getBestPopularProducts();
        popularProducts.value = productList.map((product, key) => {
          const item = product;
          if (!item.thumbnail && item.small_image) {
            item.thumbnail = product.small_image;
          }
          if (!item.url_rewrites && item.url_key && item.url_suffix) {
            item.url_rewrites = [];
            item.url_rewrites.push({
              url: `${item.url_key}${item.url_suffix}`,
            });
          }
          return item;
        });
      }
    });

    return {
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
      popularSearchResult,
      suggestionResult,
      searchValue,
      handleRouter,
      handleKeyDown,
      recentSearchList,
      popularProducts,
      isLoading,
      handleSearch,
      isDesktop,
      handleClearSearch,
    };
  },
});
